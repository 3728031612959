import { useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import { signIn, getSession } from 'next-auth/client';
import Login from '../components/login';
import Button from '../components/button';
import styles from '../styles/Home.module.css';

export async function getServerSideProps(context) {
	const session = await getSession(context);

	if (session) {
		return {
			redirect: {
				destination: '/dashboard',
				permanent: false,
			},
		};
	}

	return { props: {} };
}

export default function Home() {
	const [isModalOpen, setModalStatus] = useState(false);

	function handleLogin() {
		setModalStatus(!isModalOpen);
	}
	return (
		<>
			<div className={styles.container}>
				<Head>
					<title>BENDO</title>
					<link rel="icon" href="/favicon.ico" />
				</Head>
				<header>
					<div className={styles.logo}>
						<Image src="/logo.svg" width={84} height={30} />
					</div>
				</header>
				<div className={styles.loginContainer}>
					<h2>Vende tus cosas fácil y rápido.</h2>
					<p>
						Crea y comparte el catálogo de las cosas que quieras vender. Todo lo
						gestionas tu.
					</p>
					<Link href="/create">
						<Button primary>Crea tu catálogo</Button>
					</Link>
					<Button secondary onClick={handleLogin}>
						Tengo una cuenta
					</Button>
					<Login isOpen={isModalOpen} onClose={handleLogin} />
				</div>
				<footer>hecho con 🌶 en 🇨🇱</footer>
				<style global jsx>{`
					html,
					body,
					body > div:first-child,
					div#__next,
					div#__next > div {
						height: 100%;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
					}
				`}</style>
			</div>
		</>
	);
}
