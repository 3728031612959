import { useState } from 'react';
import Modal from 'react-modal';
import Image from 'next/image';
import { signIn } from 'next-auth/client';
import Input from '../components/input';
import Button from '../components/button';
import Loader from '../components/loader';
import styles from '../styles/Login.module.css';

Modal.setAppElement('#__next');

export default function Login({ isOpen, onClose }) {
	const [email, setEmail] = useState('');
	const [loading, setLoadingStatus] = useState(false);

	function handleChange(event) {
		const { target } = event;
		const { value } = target;

		setEmail(value);
	}

	async function handleLogin() {
		try {
			setLoadingStatus(true);
			await signIn('email', { email });
			setLoadingStatus(false);
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<Modal
			className={styles.modal}
			overlayClassName={styles.overlay}
			isOpen={isOpen}
			onRequestClose={onClose}
		>
			<button className={styles.closeButton} onClick={onClose}>
				<Image src="/close.svg" height={17} width={17} />
			</button>
			<section className={styles.content}>
				<h3>Ingresa a Bendo</h3>
				<p>Te enviaremos un link de acceso a tu email!</p>
				<Input
					type="email"
					name="email"
					value={email}
					onChange={handleChange}
					placeholder="you@email.com"
				/>
				<Button
					primary
					className={styles.loginLinkButton}
					type="button"
					onClick={handleLogin}
				>
					{loading ? <Loader /> : 'Enviar correo de ingreso'}
				</Button>
			</section>
		</Modal>
	);
}
